// AWS Project Constants
const awsmobile = {
  Auth: {
    identityPoolId: 'us-west-2:8d1ea76f-904d-48bd-ac6f-eff206620fe5',
    region: 'us-west-2',
    userPoolId: 'us-west-2_fH0k4MX6S',
    userPoolWebClientId: '6qa9cp80q644b64ngop0nlonun'
  }
};

export const UserPoolStaging = 'us-west-2_aMxYQA0HW'

export default awsmobile;